
<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      .card
        .card-body
          .form-group.row.mb-3.pl-3
            .form-group.col-lg-3.mb-0.pl-0
              multiselect(v-model="type", :options="options1", select-label="", track-by="value", label="label")
            .form-group.col-lg-3.mb-0.pl-0
              multiselect(v-model="sort_type", :options="options2", select-label="", track-by="value", label="label")
          .playerList
            .col-lg-12.card-group
              .col-lg-3.p-0
                b-card(no-body).demo-card
                  b-card-body.m-0.p-0(@click="openEdit()")
                    b-button.addbtn.width-lg(variant="outline")
                      h4 ＋　新增播放清單
              .col-lg-3(v-for="(play,index) in play_list", :key="index")
                b-card(no-body)
                  img.img-fluid(:src="play.image" @click="goEditPage(play.id)")
                  b-card-body.m-0.p-0(@click="goEditPage(play.id)")
                    b-card-title.card-title.m-0.pt-1
                      h5.ml-1.mt-1.mb-0(style="line-height: 20px; height: 40px;") {{ play.title }}
                      b-button.pl-1.pr-1.mr-1.ml-1.mt-1(style="min-width: 68px;" variant="dark" size="sm" v-if="play.type === 1") 直播節目
                      b-button.pl-1.pr-1.mr-1.ml-1.mt-1(style="min-width: 68px;" variant="dark" size="sm" v-if="play.type === 2") 特別企劃
                  b-card-body.mb-0.p-0.pl-1.pr-1.mt-1(@click="goEditPage(play.id)")
                    b-card-text.text-group.mb-1
                      p.mb-0 {{ play.video_count.toLocaleString('en-US') }} 影片
                      p.mb-0 {{ play.last_update_at.substring(0, 10) }} 更新
                  .btn-group.pr-1.pl-1.pb-1
                    b-button.btn.width-md.mr-1(variant="outline-secondary" @click="updatePlayListDetail(play)") 更新
                    b-button.btn.width-md(variant="outline-secondary" @click="deletePlayListDetail(play.id)") 刪除

  .row.mb-md-2
    .col-12
      div(class="dataTables_paginate paging_simple_numbers float-right")
        ul.pagination.pagination-rounded.mb-0
          //- pagination
          b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")

  editPlayList(:show_status="show_status" @sendForm="createPlayList")

</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import editPlayList from "@/components/editPlayList"
// import { tableData } from "./data";


/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "直播設定",
      items: [
        {
          text: "直播管理",
          href: "/livestream",
        },
        {
          text: "專區首頁編輯",
          href: "/livestream",
        },
        {
          text: "直播設定",
          active: true,
        }
      ],

      type: { label: "所有播放清單", value: 0 },
      sort_type: { label: "建立時間(新-舊)", value: 1 },

      search: {
        type: 0,
        sort_type: 1
      },
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      options1: [
        { label: "所有播放清單", value: 0 },
        { label: "直播節目", value: 1 },
        { label: "特別企劃", value: 2 },
      ],
      options2: [
        { label: "建立時間(新-舊)", value: 1 },
        { label: "建立時間(舊-新)", value: 2 },
        { label: "最近更新", value: 3 }
      ],

      play_list: [],


      modal_data: false,
      show_status: false

    };
  },
  watch: {
    type (_val) {
      this.search.type = _val.value
      this.searchPlayList()
    },
    sort_type (_val) {
      this.search.sort_type = _val.value
      this.searchPlayList()
    },
    show_status (_val) {
      if (!_val) {
        this.modal_data = false
      }
    },
    currentPage () {
      this.searchPlayList()
    }
  },
  mounted () {
    this.searchPlayList()
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.show_status = false
    })
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    editPlayList
  },
  methods: {

    goEditPage (_id) {
      this.$router.push({ path: '/live-setting/' + _id, params: {} })
    },

    openEdit () {
      this.show_status = true
    },

    // 獲得播放清單列表
    searchPlayList () {
      let vm = this
      this.$root.apis.getPlayList(
        {
          page: this.currentPage,
          type: this.search.type,
          sort_type: this.search.sort_type,
        },
        function (_response) {
          let response = _response.body
          vm.currentPage = response.page
          vm.totalRows = response.total
          vm.perPage = response.per_page
          vm.play_list = response.items
        }
      )
    },
    // 建立播放清單列表
    createPlayList (_data) {
      let vm = this
      this.$root.apis.createPlayList(_data,
        function (_response) {
          vm.show_status = false
          vm.$root.common.showSingleNotify("清單建立成功！")
        },
        function (_response) {
          vm.$root.common.showErrorNotify(_response.body.data)
        },
      )
    },
    // 更新播放清單列表
    updatePlayListDetail (_data) {
      let vm = this
      const item = {
        type: _data.type,
        title: _data.title,
        image: _data.image
      }
      this.$root.apis.updatePlayListDetail(_data.id, item, function () {
        vm.$root.common.showSingleNotify("更新成功")
      })
    },

    // 刪除播放清單列表
    deletePlayListDetail (_id) {
      let vm = this
      this.$root.common.confirmAction("刪除播放清單", "確定要刪除這個播放清單?", "刪除", "先不要",
        function () {
           vm.$root.apis.deletePlayListDetail(_id, function () {
            vm.searchPlayList()
            vm.$root.common.showSingleNotify("刪除成功")
          })
        })
    },




  }
};
</script>


<style lang="sass" scoped>
  .img-fluid
    width: 363px
    height: 169px
    margin: auto
    object-fit: cover
    cursor: pointer
  .card-title,.text-group,.btn-group
    display: flex
    justify-content: space-between
    align-items: flex-start
    gap: 2px
    .btn
      border-radius: 6px !important
  .playerList
    display: flex
    flex-wrap: wrap
    justify-content: space-start
    gap: 2rem
    .demo-card
      height: 300px
      text-align: center
      vertical-align: middle
      position: relative
      .addbtn
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        margin: auto
    .card-group
      display: flex
      justify-content: flex-start
      flex-wrap: wrap

</style>
