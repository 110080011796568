<template lang="pug">
b-modal#modal-choice-news(
  v-model="modalShow"
  :title="modal_data ? '編輯播放清單' : '新增播放清單'", 
  title-class="font-18", 
  body-class="p-0" 
  size="lg"
)

  .row
    .m-3.col-10
      form.form-horizontal(role="form")
        .form-group.mb-2
          label 播放清單名稱
          br
          multiselect(v-model="type", :options="options1", select-label="", track-by="value", label="label")
        .form-group.mb-2
          label 播放清單名稱
          br
          input.form-control(
            v-model="form.title",
            type="text",
            placeholder="請輸入播放清單名稱"
          )
        .form-group.mb-2
          label 播放清單封面
          br
          SelectImage(
            key="NewsImage"
            itemKey="NewsImage"
            type="image"
            @upDateImage="setNewsImage"
            :value="currentNewsImage"
          )
          .img-view
            i.fe-image(v-if="!currentNewsImage")
            img(v-if="currentNewsImage" :src='currentNewsImage.url' rounded )
        .form-group.mb-2
          label Youtube匯入連結
          br
          input.form-control(
            v-model="form.youtube_playlist_uri",
            type="text",
            placeholder="此欄位為所有影片對應清單，請輸入YT對應播放清單網址，請勿輸入短網址(完整版)"
          )

  template(#modal-footer='{ ok, cancel, hide }')
    b-button(size='md' variant='danger' @click='cancel()')
      | 取消
    b-button(size='md' variant='success' @click='sendForm()')
      | 確定

</template>
<script>
import Multiselect from "vue-multiselect";
import SelectImage from "@/components/select-image";
export default {
  props: ['show_status', 'modal_data'],
  name: "PlayListChoice",
  data () {
    return {
      modalShow: false,
      type: { label: "直播節目", value: 1 },
      currentNewsImage: null,
      form: {
        title: '',
        type: 1,
        image: '',
        youtube_playlist_uri: '',
      },
      options1: [
        { label: "直播節目", value: 1 },
        { label: "特別企劃", value: 2 },
      ],
    }
  },
  watch: {
    show_status (_status) {
      this.modalShow = _status
      if (!_status) {
        this.form = {
          title: '',
          type: 1,
          image: '',
          youtube_playlist_uri: '',
        }
      }
    },
    type (_val) {
      this.form.type = _val.value
    },
    modal_data (_val) {
      console.log(_val);
      this.form = {
        title: _val.title,
        type: _val.type,
        image: _val.image,
        youtube_playlist_uri: _val.uri,
      }
      this.currentNewsImage = { id: "", url: _val.image }
    },
    'form.youtube_playlist_uri' (_url) {
      if (!_url) {
        return
      }

      this.getPlayListDetail()
    }
  },
  created () {

  },
  computed: {

  },
  methods: {
    clickOk () {
      this.modalShow = false
    },
    // 設定清單封面
    setNewsImage (_image) {
      this.currentNewsImage = _image
      this.form.image = _image.url
    },
    sendForm () {
      this.$emit('sendForm', this.form)
    },
    // 獲得播放清單資料
    getPlayListDetail () {
      let vm = this
      this.$root.apis.getYoutubePlayListDetail(this.form.youtube_playlist_uri, function (_response) {
        let detail = _response.body.data
        vm.form.title = detail.title
        vm.form.image = detail.image
        vm.currentNewsImage = { id: "", url: detail.image }
      })
    }
  },
  components: {
    Multiselect,
    SelectImage
  },
}
</script>
<style lang="sass" scoped>
.category-list
  border-right: 1px solid #e5e8eb
  width: 149px
  max-height: 80vh
  overflow: auto
  .category-item
    padding: 1rem 1.5rem
    color: #6b7280e6
    cursor: pointer
    &.active
      background: #eee
.types-list
  display: flex
  gap: 5px
.choice-main
  flex: 1
  padding: 1rem 0.75rem
  max-height: 80vh
  overflow: auto
::v-deep .width_100
  width: 150px !important
.img-view
  width: 300px
  height: 200px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  img
    width: 100%
    height: 100%
    object-fit: cover
  i
    font-size: 30px
</style>
